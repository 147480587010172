<template>
  <div class="aboutus" ref='mydiv'>
    <div style="position: relative;height:2.4rem">
      <img
        class="bannerImg"
        src="../assets/images/aboutus/banner.png"
        alt=""
      />
    </div>
    <div :class="fixed == true ? 'navigationfixed' :'navigation'">
        <div :class="anchorpoint==1?'navigation-title-actived':'navigation-title'" @click="goAnchorpoint(1,'#m1')">新闻资讯</div>
        <div :class="anchorpoint==2?'navigation-title-actived':'navigation-title'" @click="goAnchorpoint(2,'#m2')">智算中心</div>
        <div :class="anchorpoint==3?'navigation-title-actived':'navigation-title'" @click="goAnchorpoint(3,'#m3')">中科逆熵</div>
        <div :class="anchorpoint==4?'navigation-title-actived':'navigation-title'" @click="goAnchorpoint(4,'#m4')">联系我们</div>
    </div>
    <div class="aboutus-main">
        <div class="main">
            <div class="main-mid" id="m1" ref='mmm'>
                <div class="title">新闻资讯</div>
                <div class="main-news">
                    <div class="main-news-left" @click="goDetails(photoList[0])">
                        <img :src="'/oss'+photoList[0].smallImgPath" alt="" style="width:5.84rem;height:3.6rem">
                        <div class="main-news-left-cont">
                            <div class="cont-left">
                                <div class="cont-left-time1">{{photoList[0].createDate.slice(5,10)}}</div>
                                <div class="cont-left-time2">{{photoList[0].createDate.slice(0,4)}}</div>
                            </div>
                            <div class="cont-right">
                                <div class="cont-right-title"  :title="photoList[0].title">{{photoList[0].title}}</div>
                                <div class="cont-right-cont">{{photoList[0].summarize}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="main-news-right">
                        <div class="main-news-right-single" v-for="(item,index) in photoList.slice(1,4)" :key="index" @click="goDetails(item)">
                            <img :src="'/oss'+item.smallImgPath" alt="" style="width:1.6rem;height:1.4rem">
                            <div class="news-single-right">
                                <div class="news-single-right-title"  :title="item.title">{{item.title}}</div>
                                <div class="news-single-right-cont">{{item.summarize}}</div>
                                <div class="news-single-right-time">{{  formatTime(item.createDate) }}</div>
                            </div>
                        </div>
                        <!-- <div class="main-news-right-single">
                            <img src="../assets/images/aboutus/contactus1.png" alt="" style="width:1.6rem;height:1.4rem">
                            <div class="news-single-right">
                                <div class="news-single-right-title">南京，机遇之城“新战队”</div>
                                <div class="news-single-right-cont">近日，第三届江苏发展大会的南京主场活动——金陵故乡行南京高质量发展汇报会上，寒武纪、欧葆庭、冰鉴、安迪...</div>
                                <div class="news-single-right-time">2023 07-05</div>
                            </div>
                        </div>
                        <div class="main-news-right-single">
                            <img src="../assets/images/aboutus/contactus1.png" alt="" style="width:1.6rem;height:1.4rem">
                            <div class="news-single-right">
                                <div class="news-single-right-title">南京，机遇之城“新战队”</div>
                                <div class="news-single-right-cont">近日，第三届江苏发展大会的南京主场活动——金陵故乡行南京高质量发展汇报会上，寒武纪、欧葆庭、冰鉴、安迪...</div>
                                <div class="news-single-right-time">2023 07-05</div>
                            </div>
                        </div> -->
                        <div class="main-news-right-more"><div style="font-size: 0.14rem;color: #0061E3;padding-right:0.09rem" @click="goNews">更多资讯</div><img src="../assets/images/aboutus/more.png" alt="" style="width:0.14rem;height:0.14rem"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-region" id="m2">
            <div class="main-company-top">
                <img src="../assets/images/aboutus/regionlogo.png" alt="" style="width:0.55rem;height:0.57rem">
                <div class="main-company-top-title">南京智能计算中心</div>
            </div>
            <div class="main-company-bottom">
                <div class="company-left">
                    <div>南京智能计算中心由麒麟科创园携手中科寒武纪科技股份有限公司共同打造，于2021年7月正式投入运营，总投资13亿元。智算中心分三期建设，一期建设已完毕，二期建设今年即将完成，届时将成为长三角地区规模最大、算力最高的智能计算中心，三期建成后，预计算力总规模将突破1250Pops。当前，智算中心的算力规模达570Pops@INT16，峰值利用率约为80%，服务包括中科院计算所、中国科学技术大学、南京大学、寒武纪、行歌、浪潮在内的近百家科研院所、高校机构、创新企业，广泛应用于大模型、数字人、自动驾驶、生物信息、教育科研等领域。累计为企业降本超亿元，增加经济效益十多亿元。</div>
                    <div>2023年6月，南京智能计算中心获科技部批复，成为全国首批九家“国家新一代人工智能公共算力开放创新平台”之一，也是全省唯一一家。这标志着南京智能计算中心自此进入“国家队”方阵，纳入全国人工智能算力战略体系。</div>
                </div>
                <div class="company-right">
                    <!-- <video src="@/assets/video/regionIntroduction.mp4" poster="../assets/video/videoPoster.png" style="width:4.8rem;height:2.7rem" controls width="100%"></video> -->
                </div>
            </div>
            <div class="region-data">
                <div class="region-data-single">
                    <div style="padding-bottom:0.2rem">总投资</div>
                    <div><span style="font-size:0.36rem;color:#0061E3">13</span>亿元</div>
                </div>
                <div class="region-data-single">
                    <div style="padding-bottom:0.2rem">当前算力规模</div>
                    <div><span style="font-size:0.36rem;color:#0061E3">570</span>Pops</div>
                </div>                   
                <div class="region-data-single">
                    <div style="padding-bottom:0.2rem">服务客户</div>
                    <div><span style="font-size:0.36rem;color:#0061E3">90</span>+</div>
                </div>                   
                <div class="region-data-single">
                    <div style="padding-bottom:0.2rem">峰值利用率</div>
                    <div><span style="font-size:0.36rem;color:#0061E3">80</span>%</div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main-company" id="m3">
                <div class="main-company-top">
                    <img src="../assets/images/aboutus/companylogo.png" alt="" style="width:0.68rem;height:0.6rem">
                    <div class="main-company-top-title">南京中科逆熵科技有限公司</div>
                </div>
                <div class="main-company-bottom">
                    <div class="company-left">
                        <div style="padding:0.25rem 0 0.16rem"><span style="font-size:0.18rem;color:#333333;font-weight: bold;">南京中科逆熵科技有限公司</span>南京中科逆熵科技有限公司致力于引领推动南京智算中心的算力应用，倾力打造 “<span style="font-size:0.18rem;color:#0061E3;">核心平台+ 数据运营+ 生态合作</span>”的人工智能产业生态圈，促进人工智能技术与传统产业结合，为解决方案供应商提供人工智能算力服务。</div>
                        <div>中科逆熵公司是由南京麒麟科技创新园国资参股、南京智能计算中心指定的唯一运营企业，是中科寒武纪官方授权生态合作伙伴，与数十家科研院所、高校、人工智能生态企业有密切的合作关系，在人工智能算力平台运营、研发及生态拓展有丰富的经验，已获得ISO9001质量管理体系认证和江苏省科技型中小企业认证，具备“信息系统安全等级保护第三级”等资质。</div>
                    </div>
                    <div class="company-right">
                        <!-- <video src="@/assets/video/company.mp4" poster="../assets/video/videoPoster.png" style="width:4.8rem;height:2.7rem" controls width="100%"></video> -->
                    </div>
                </div>                
            </div>
        </div>
            <div class="main-bottom" id="m4">
                <div class="title">联系我们</div>
                <div class="contact-us">
                    <div class="contact-us-single">
                        <img src="../assets/images/aboutus/contactus1.png" alt="" class="contact-us-single-img">
                        <div class="contact-us-single-address">
                            <img src="../assets/images/aboutus/address.png" alt="" style="width:0.14rem;height:0.17rem">
                            <span style="padding-left:0.1rem">江苏省南京市江宁区运粮河东路南京市政务数据中心</span>
                        </div>
                    </div>
                    <div class="contact-us-single">
                        <img src="../assets/images/aboutus/contactus2.png" alt="" class="contact-us-single-img">
                        <div class="contact-us-single-address">
                            <img src="../assets/images/aboutus/address.png" alt="" style="width:0.14rem;height:0.17rem">
                            <span style="padding-left:0.1rem">南京市麒麟科技创新园麒麟人工智能产业园5号楼609室</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    
  </div>
</template>
<script type="text/javascript" defer="true">
/* eslint-disable */
import api from '@/api/api'
import DateUtils from '@/assets/filters/dateUtils'
export default {
    data () {
        return {
           anchorpoint: 1,
           photoList: [],
           fixed:false,
           Id:'',
           print:''
        }
    },
    created () {
        this.queryList()
        this.$nextTick(()=>{this.toLocal()})
    },

    mounted() {
        this.$nextTick(()=>{this.toLocal()})
        let _this=this;
	    _this.$nextTick(
            function(){
			window.addEventListener('scroll',_this.handleScroll)
	    })	
        window.addEventListener('scroll', this.fixedActiveBtn);
        setTimeout(() => {
        this.$nextTick(()=>{
            if(localStorage.getItem('toId')){
                this.print=localStorage.getItem('toId').charAt(localStorage.getItem('toId').length-1) 
                this.goAnchorpoint(this.print,this.Id)
            }
            })  
        }, 500);

    },
    methods: {
        toLocal(){
            //查找存储的锚点id
            this.Id=localStorage.getItem('toId');
            //锚点存在跳转
            if(this.Id){
                toElement.scrollIntoView()
            }
        },
        fixedActiveBtn() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            scrollTop >=420 ? this.fixed = true : this.fixed = false
        },
        formatTime (val) {
           return DateUtils.dateFormat(new Date(val)).slice(0, 10)
        },
        goAnchorpoint (val, anchor) {
            this.anchorpoint = val
            this.$nextTick(() => {
                document.querySelector(anchor).scrollIntoView({
                // 不想要滚动动画,则设置为"instant"
                behavior: 'smooth', // 滚动动画
                block: 'center'
                })
             });
        },
        goNews(){
            this.$router.push({
                path:'/news',
            })
        },
        goDetails (val) {
            if (val.wxUrl) {
               window.open(val.wxUrl)
               return 
            }
            this.$router.push({
                path:'/newsDetails',
                query:{ id:val.id}
                })
        },
        queryList () {
            api.newslist({
                pageNum: 1,
                pageSize: 999
                }).then(res => {
                 if (res.code === 200 && res.data.list) {
                   this.photoList = res.data.list
                }
            })
        }      
    },
    destroyed() {
        localStorage.setItem('toId','');
    }
}
</script>
<style lang="scss" >
.main-mid .el-tab-pane{
    display: flex;
    justify-content: space-between;
}
</style>
<style lang="scss" >
.aboutus {
  .bannerImg {
    height: 2.4rem;
  }
  .navigationfixed{
    position: fixed;   //固定定位
    top: 0;
    z-index: 200;
    height: 0.72rem;
    width: 100%;
    background:#ffffff;
    display: flex;
    justify-content: center;
    font-size: 0.18rem;
    font-family: Source Han Sans CN;
  }
  .navigation{
    height: 0.72rem;
    background:#ffffff;
    display: flex;
    justify-content: center;
    font-size: 0.18rem;
    font-family: Source Han Sans CN;
  }
    .navigation-title-actived{
        font-weight: bold;
        color: #0061E3;
        line-height: 0.72rem;
        padding:0 0.32rem;
        margin:0 0.2rem;
        border-bottom: 0.01rem solid #0061E3;
    }
    .navigation-title{
        font-weight: bold;
        color: #333333;
        line-height: 0.72rem;
        padding:0 0.32rem;
        margin:0 0.2rem;
        cursor: pointer;
    }
        // .navigation{
        //     width: 12rem;
        //     margin:0 auto;
        //     position: relative;  
        // }
 
  .aboutus-main{
      width: 100%;
    //   padding:0.6rem 0;
    //   background: #F5F5F5;
      background: #FFFFFF;
      .main{
          background: #F5F5F5;
        }
        .main-region{
          width:12rem;
          margin:0 auto;
          padding:0.4rem 0;
            .main-company-top{
                display: flex;
                justify-content: left;
                align-items: center;
                padding-bottom: 0.04rem;
                .main-company-top-title{
                    font-size: 0.26rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #0061E3;
                    padding-left: 0.22rem;
                }
            }
            .main-company-bottom{
                display: flex;
                justify-content: space-between;
                padding-bottom: 0.4rem;
                .company-left{
                    padding-top:0.25rem;
                    width: 6.59rem;
                    font-size: 0.16rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 0.24rem;
                    text-indent:2em
                }
            }
            .region-data{
                display: flex;
                justify-content: left;
                .region-data-single{
                    width: 25%;
                    font-size: 0.16rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #000000;
                    line-height: 0.24rem;
                }
            }
        }
        .main-company{
          width:12rem;
          margin:0 auto;
            padding:0.4rem 0;
            .main-company-top{
                display: flex;
                justify-content: left;
                align-items: center;
                padding-bottom: 0.04rem;
                .main-company-top-title{
                    font-size: 0.26rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #0061E3;
                    padding-left: 0.22rem;
                }
            }
            .main-company-bottom{
                display: flex;
                justify-content: space-between;
                .company-left{
                    width: 6.59rem;
                    font-size: 0.16rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 0.24rem;
                    text-indent:2em
                }
            }
        }
        .main-mid{
           width:12rem;
           margin:0 auto;
            padding:0.6rem 0;
           .title{  
                font-size:0.28rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                text-align:center;
                margin-bottom: 0.21rem;
           } 
            .main-mid_title{
                padding-left: 0.07rem;
                font-size: 0.18rem;
                line-height: 0.24rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                border-left: 0.03rem solid #0061E3;
                margin-bottom: 0.2rem;
            }
            .main-news{
                display: flex;
                justify-content: space-between;
                .main-news-left{
                    width: 5.84rem;
                    height: 4.8rem;
                    background: #FFFFFF;
                    border-radius: 0.04rem;
                    cursor: pointer;
                    .main-news-left-cont{
                        padding:0.25rem 0.2rem 0.3rem 0.3rem;
                        display: flex;
                        justify-content: space-between;
                        font-family: Source Han Sans CN;
                        .cont-left{
                            width:0.6rem;
                            .cont-left-time1{
                                font-size: 0.18rem;
                                font-weight: bold;
                                color: #333333;
                            }
                            .cont-left-time2{
                                font-size: 0.22rem;
                                font-weight: bold;
                                color: #DBDBDB;
                            }
                        }
                        .cont-right{
                            width:4.53rem;
                            .cont-right-title{
                                font-size: 0.16rem;
                                font-weight: bold;
                                color: #333333;
                                padding-bottom: 0.09rem;
                                cursor: pointer;
                            }
                            .cont-right-title:hover{
                                color: #0061E3;
                            }
                            .cont-right-cont{
                                font-size: 0.14rem;
                                font-weight: 400;
                                color: #333333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; 
                                line-clamp: 2; 
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
                .main-news-right{
                    width: 5.84rem;
                    height: 4.8rem;
                    .main-news-right-single{
                        display: flex;
                        justify-content: left;
                        width: 100%;
                        height: 1.4rem;
                        background:#FFFFFF;
                        margin-bottom: 0.16rem;
                        cursor: pointer;
                        .news-single-right{
                            padding-left: 0.2rem;
                            .news-single-right-title{
                                font-size: 0.16rem;
                                font-weight: bold;
                                color: #333333;
                                margin:0.2rem 0.2rem  0.1rem 0;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1; 
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                            .news-single-right-title:hover{
                                color: #0061E3;
                            }
                            .news-single-right-cont{
                                width: 3.81rem;
                                margin-bottom: 0.24rem;
                                font-size: 0.14rem;
                                color: #333333;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; 
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                            .news-single-right-time{
                                font-size: 0.14rem;
                                font-weight: bold;
                                color: #A8A8A8;                             
                            }
                        }
                    }
                    .main-news-right-more{
                        display: flex;
                        justify-content: right;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }
        }
        .main-bottom{
          width:12rem;
          margin:0 auto;
          padding: 0.4rem 0;
           .title{  
                font-size: 0.28rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                text-align:center;
                margin-bottom: 0.21rem;
           }
           .contact-us{
               display: flex;
               justify-content: space-between;
               .contact-us-single{
                   width: 5.8rem;
                   .contact-us-single-img{
                        width: 5.8rem;
                        height: 3.2rem;
                   }
                   .contact-us-single-address{
                       display: flex;
                       justify-content: left;
                       align-items: center;
                       font-size: 0.16rem;
                       font-family: Source Han Sans CN;
                       font-weight: 500;
                       color: #333333;
                   }
               }
           }
        }
    }
}
.product_top_cont{
    display: flex;
    justify-content: space-between;
    .product_top_cont_single{
        font-size: 0.14rem;
        color: #666666;
        width: 24%;
        .single_title{
            font-size: 0.14rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            margin-bottom: 0.2rem;
        }
    }
}
</style>
